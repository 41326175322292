.align-right {
  /* display: flex; */
  /* justify-content: right; */
  /* justify-content: flex-end; */
  /* margin: auto; */
  margin-left: 25vw;
  margin-top: -190px;
  /* justify-self: self-end; */
  /* margin-right: -5px; */
}

@media (max-width: 991px) {
  .align-right {
    /* display: flex; */
    margin-top: 15px;
    margin-left: 0px;
    height: 450px;
    width: 300px;
  }
}
