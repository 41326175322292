// google fonts
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

// color mode toggle mixins
@mixin lightMode {
  --dark-color: #274760;
  --primary-color: #86bbf1;
  --secondary-color: #fff;
  --background-color: #fafafa;
  --side-link-hover-color: #f6f6f6;
  --side-text-color: #808191;
  --logo-color: var(--primary-color);
  --xl-text-color: #292929;
  --lg-text-color: #525252;
  --base-text-color: #676767;
  --text-color-inverted: #292929;
  --bg-color-inverted: #ffffff;
  --border-color-inverted: #f6f6f6;
  --toggle-btn-border-color: #f3f3f3;
  --toggle-btn-bg-color: #fff;
  --toggle-btn-icon-color: var(--primary-color);
  --thead-bg-color: #f2f4ff;
  --tbl-border-color: #f2f4ff;
  --scroll-track-bg-color: #fff;
  --scroll-thumb-bg-color: #e9e9e9;
}

@mixin darkMode {
  --dark-color: white;
  --primary-color: #475be8;
  --secondary-color: #2e2e48;
  --background-color: #383854;
  --side-link-hover-color: #525270;
  --side-text-color: #e1e1e1;
  --logo-color: #fff;
  --xl-text-color: #ffff;
  --lg-text-color: #f3f3f3;
  --base-text-color: #e0e0e0;
  --text-color-inverted: #fff;
  --bg-color-inverted: #292929;
  --border-color-inverted: #575777;
  --toggle-btn-border-color: #525270;
  --toggle-btn-bg-color: #525270;
  --toggle-btn-icon-color: #bdbdbd;
  --thead-bg-color: #383854;
  --tbl-border-color: ##383854;
  --scroll-track-bg-color: #2e2e48;
  --scroll-thumb-bg-color: #575777;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;
}

// color mode toggle in body
body {
  font-family: var(--font-family-lato);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  // by default light mode is on
  @include lightMode();

  // add dark-mode class on the body if dark mode is set true
  &.dark-mode {
    @include darkMode();
  }
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

// theme toggle button
// .theme-toggle-btn {
//   background-color: var(--bg-color-inverted);
//   position: fixed;
//   right: 0;
//   top: 20px;
//   width: 48px;
//   height: 40px;
//   border-top-left-radius: 30px;
//   border-bottom-left-radius: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: none;
//   outline: 0;
//   z-index: 999;
//   cursor: pointer;
//   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

//   .theme-icon {
//     width: 24px;
//   }
// }

.page-wrapper {
  background-color: var(--background-color);
  min-height: 100vh;
  overflow: hidden;
}

.content-wrapper {
  background-color: var(--background-color);
  min-height: 100vh;
  padding: 50px 58px;
  margin-left: 260px;
  margin-top: 2.5rem !important;

  @media (max-width: 1400px) {
    padding: 32px;
  }

  @media (max-width: 1200px) {
    margin-left: 56px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 24px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
    padding: 16px;
  }
}

// content
.content-area {
  & > section {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sidebar-toggle-btn {
  display: none;
  // position: absolute;
  // top: 20px;
  // left: 20px;
  // margin: 20px;
  background: var(--toggle-btn-bg-color);
  border: 1px solid var(--toggle-btn-border-color);
  cursor: pointer;
  // z-index: 1000;
  padding: 8px;
  border-radius: 4px;
  
  svg {
    color: var(--toggle-btn-icon-color);
  }

  @media (max-width: 768px) {
    display: block;
  }
}