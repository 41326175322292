.data-table-title{
 color: var(--dark-color)
}

.form-item{
    color: var(--dark-color)
}

.btn-bg{
    color: var(--dark-color)
}


.cs_btn.cs_style_1 {
    font-family: 'Figtree', sans-serif;
    border-radius: 24px;
    box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
    // box-shadow: 0px 4px 20px 0px var(--dark-color);
    font-weight: 500;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 40px;
    outline: none;
    border: none;
    position: relative;
    background: transparent;
    @media (max-width: 991px) {
      padding: 12px 30px;
    }
    span {
      position: relative;
      z-index: 2;
    }
    i {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 15px;
      height: 11px;
      z-index: 2;
      transition: all 0.4s ease-in-out;
    }
    img {
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      transition: all 0.4s ease-in-out;
      filter: brightness(0) invert(1);
      &:first-child {
        left: -20px;
      }
      &:last-child {
        left: 0;
      }
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: inherit;
      left: 0;
      top: 0;
      transition: all 0.4s ease-in-out;
    }
    &::before {
      background-image: linear-gradient(134deg, #307bc4 0%, #274760 100%);
    }
    &::after {
      background-image: linear-gradient(270deg, #307bc4 0%, #274760 100%);
      opacity: 0;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
      &::after {
        opacity: 1;
      }
      img {
        &:first-child {
          left: 0px;
        }
        &:last-child {
          left: 20px;
        }
      }
    }
  }