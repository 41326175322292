.content-area-table {
  background: var(--secondary-color);
  border-radius: 5px;
  box-shadow: var(--light-shadow1);
  padding: 12px 16px;

  @media (max-width: 768px) {
    padding: 12px;
  }

  .data-table-title {
    font-size: 30px;
    color: var(--xl-text-color);
    margin-bottom: 12px;
  }

  .data-table-diagram {
    border-radius: 6px;
    border: 1px solid var(--tbl-border-color) !important;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--scroll-track-bg-color);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100vh !important;
      background-color: #e9e9e9;
      outline: 1px solid rgba(0, 0, 0, 0.02);
      outline-offset: -1px;
    }

    table {
      min-width: 900px;
      width: 100%;
      border-collapse: collapse;
      color: var(--text-color-inverted);

      td,
      th {
        padding: 14px 12px;
        @media (max-width: 768px) {
          font-size: 15px;
          padding: 12px 10px;
        }
      }

      thead {
        text-align: left;
        font-size: 17px;
        background-color: var(--thead-bg-color);

        th {
          &:last-child {
            text-align: center;
          }
        }
      }

      .dt-cell-action {
        text-align: center;
      }

      .dt-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 6px;

        .dt-status-dot {
          width: 8px;
          height: 8px;
          border-radius: 100%;

          &.dot-pending {
            background-color: var(--orange-color);
          }
          &.dot-canceled {
            background-color: var(--red-color);
          }
          &.dot-delivered {
            background-color: var(--green-color);
          }
        }

        .dt-status-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .action-dropdown-btn {
    color: var(--text-color-inverted);
    position: relative;
  }

  .action-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    height: auto;
    min-width: 80px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 1px 4px 0px;
    background-color: var(--secondary-color);
    padding: 6px 10px;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid var(--border-color-inverted);
    text-align: left;
  }

  .dropdown-menu-item {
    margin: 8px 0;
  }

  .dropdown-menu-link {
    color: var(--text-color-inverted);
    font-weight: 600;
    transition: var(--default-transition);

    &:hover {
      opacity: 0.9;
    }
  }
}

// .cs_btn {
//   font-family: 'Figtree', sans-serif;
// }

.cs_btn.cs_style_1 {
  font-family: 'Figtree', sans-serif;
  border-radius: 24px;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
  font-weight: 500;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 40px;
  outline: none;
  border: none;
  position: relative;
  background: transparent;
  @media (max-width: 991px) {
    padding: 12px 30px;
  }
  span {
    position: relative;
    z-index: 2;
  }
  i {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 15px;
    height: 11px;
    z-index: 2;
    transition: all 0.4s ease-in-out;
  }
  img {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    filter: brightness(0) invert(1);
    &:first-child {
      left: -20px;
    }
    &:last-child {
      left: 0;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
  }
  &::before {
    background-image: linear-gradient(134deg, #307bc4 0%, #274760 100%);
  }
  &::after {
    background-image: linear-gradient(270deg, #307bc4 0%, #274760 100%);
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 1;
    }
    img {
      &:first-child {
        left: 0px;
      }
      &:last-child {
        left: 20px;
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end; // Aligns the button to the right
  margin-top: 20px; // Adds some space above the button
  margin-bottom: 20px; // Adds some space below the button

  @media (max-width: 768px) {
    flex-direction: column; // Changes layout to column on smaller screens
    align-items: center; // Centers the button vertically
  }
}
